import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: 'Edin Kaymakqi | Front-end web developer',
  lang: 'en',
  description: 'Front-end developer living in Vancouver',
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: 'Edin.',
  subtitle: "I'm a front-end web developer.",
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: 'me.jpg',
  paragraphOne:
    'I am a very curious guy who loves problem solving. I love working with the web and building clean and modern looking websites / apps using modern technologies & libraries.',
  paragraphTwo: `When not coding, you can probably spot me jogging alongside kitsilano beach. I also love playing sports. My all time favorite is probably beach volleyball during summer. 👌`,
  paragraphThree: '',
  resume: 'https://drive.google.com/open?id=1SLS1Y3nf9Y4jhvfI9KIJgd-A94Zu2qCe',
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'sage.png',
    title: 'Sage Foundation | Social Acceptance of Great Excellence',
    info:
      "Wordpress website built for a real client who's mission is to create healthy dialogue with youth and new immigrants, supporting their integration and sense of belonging.",
    info2: 'Built with: Wordpress, PHP, HTML5, SASS, Javascript.',
    url: 'https://www.sagefoundation.net/',
    repo: '',
  },
  {
    id: uuidv1(),
    img: 'larivo.png',
    title: 'Larivo | #Mental Health',
    info:
      'Cross platform native app where people can tell and listen to stories about mental health and incredible journeys to recovery.',
    info2: 'Built with: React Native, Prisma, GraphQL/Apollo, Javascript, MySQL.',
    url: 'https://larivo.ca/',
    repo: 'https://github.com/redacademy/larivo-WS2-2019',
  },
  {
    id: uuidv1(),
    img: 'boom.jpg',
    title: 'Boomtown | Share, Borrow, Prosper',
    info:
      'Full-stack website for sharing and borrowing different items. Users can upload an image, give it a description, choose appropriate tags and share it!',
    info2:
      'Built with: React, Material UI, Final Form, Javascript, Node.js, Express, GraphQL/Apollo, PostgresSQL.',
    url: 'https://boom.academy.red',
    repo: 'https://github.com/EdinK1/boomtown',
  },
  {
    id: uuidv1(),
    img: 'tent.jpg',
    title: 'Inhabitent | Camping made easy',
    info:
      'Wordpress custom theme built for a fictional camping company with a shop page where the company can display products for sale and a blog section for custom posts.',
    info2: 'Built with: Wordpress, PHP, Javascript, Jquery, Sass.',
    url: 'https://tent.academy.red',
    repo: 'https://github.com/EdinK1/Inhabitent',
  },
  {
    id: uuidv1(),
    img: 'rten.jpg',
    title: 'R10 | Conference App',
    info:
      'Cross platform app that helps companies manage their schedule when they have a conference going on.',
    info2: 'Built with: React-Native, GraphQL, Javascript.',
    url: '',
    repo: 'https://github.com/EdinK1/r10',
  },
  {
    id: uuidv1(),
    img: 'coral.jpg',
    title: 'Coral | Write beautifully',
    info:
      'Note taking app that allows users to share a note with another user which allows the other user to edit the note with you in real time.',
    info2: 'Built with: Meteor.js, React, Javascript, styled-components.',
    url: '',
    repo: 'https://github.com/EdinK1/coral',
  },
  {
    id: uuidv1(),
    img: 'aloha.jpg',
    title: 'Aloha | E-commerce at its finest',
    info:
      'Single page website built for a fictional e-commerce company. Mobile first approach, fully responsive and provides features like carousels where the company can showcase all of their products for sale.',
    info2: 'Built with: HTML5, SASS, Javascript, Jquery.',
    url: 'https://aloha-apparel.netlify.com/',
    repo: 'https://github.com/EdinK1/Aloha-Apparel',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'edinkaymakchi@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'github',
      url: 'https://github.com/EdinK1/',
    },
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/edin-kaymakqi-3243b3163/',
    },
    {
      id: uuidv1(),
      name: 'phone',
      url: 'tel:6042407966',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false,
};
